import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`Practice Rope Climbs`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“White”`}</strong></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`3 Rope Climbs to 15′`}</p>
    <p>{`10 T2B`}</p>
    <p>{`21 OH Walking Lunges (45/25)`}</p>
    <p>{`400M Run`}</p>
    <p><em parentName="p">{`*`}{`compare to 9/2/16`}</em></p>
    <p><strong parentName="p">{`*`}{`We will be shooting a video during Wednesday’s noon class so if
you’d like to be in it show up!  No open gym during the shoot, from 11-1
please.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      